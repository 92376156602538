import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));

// echart page
const AppEchart = Loadable(lazy(() => import('app/views/charts/echarts/AppEchart')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));
const AppListAreas = Loadable(lazy(() => import('app/views/dashboard/menu_areas/AppListAreas')));
const AppSystem = Loadable(lazy(() => import('app/views/dashboard/menu_it/system/AppSystem')));
const AppPayment = Loadable(lazy(() => import('app/views/dashboard/menu_it/payment/AppPayment')));
const AppLogsActivity = Loadable(
  lazy(() => import('app/views/dashboard/menu_admins/logs/admin/AppLogs'))
);
const AppLogsAPI = Loadable(lazy(() => import('app/views/dashboard/menu_admins/logs/api/AppLogs')));
const AppListClient = Loadable(
  lazy(() => import('./views/dashboard/menu_clients/lis_client/AppListClient'))
);
const AppListMitra = Loadable(
  lazy(() => import('app/views/dashboard/menu_mitra/table_mitra/AppListMitra'))
);
const AppListReportShipping = Loadable(
  lazy(() => import('app/views/dashboard/reports/reports_shipping/AppListTransaction'))
);
const AppReportListTransactionVA = Loadable(
  lazy(() => import('app/views/dashboard/reports/reports_transaction/AppListTransaction'))
);
const AppListAdmins = Loadable(
  lazy(() => import('app/views/dashboard/menu_admins/list_admin/AppTableAdmin'))
);
const AppListTransaction = Loadable(
  lazy(() => import('app/views/dashboard/menu_trasanctions/list_transactions/AppListTransaction'))
);
const AppListComission = Loadable(
  lazy(() => import('app/views/dashboard/reports/reports_comission/AppListComission'))
);
const AppShipping = Loadable(
  lazy(() => import('app/views/dashboard/menu_it/shipping/AppShipping'))
);
const AppIntegrationInbound = Loadable(
  lazy(() => import('app/views/dashboard/menu_it/integration/inbound/AppInbound'))
);

const AppIntegrationOutbound = Loadable(
  lazy(() => import('app/views/dashboard/menu_it/integration/outbound/AppOutbound'))
);

const AppListMitraConfPayment = Loadable(
  lazy(() => import('./views/dashboard/menu_mitra/table_conf_payment/AppTableConfMitraPayment'))
);

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: '/dashboard/default',
        element: <Analytics />,
        auth: authRoles.admin
      },

      // create components in hire..!

      {
        path: '/dashboard/report-shipping',
        element: <AppListReportShipping />
      },

      {
        path: '/dashboard/report-transaction-va',
        element: <AppReportListTransactionVA />
      },

      {
        path: '/dashboard/report-comission',
        element: <AppListComission />
      },

      {
        path: '/dashboard/mitra',
        element: <AppListMitra />
      },

      {
        path: '/dashboard/conf_payment/mitra',
        element: <AppListMitraConfPayment />
      },

      {
        path: '/dashboard/client',
        element: <AppListClient />
      },

      {
        path: '/dashboard/list-admins',
        element: <AppListAdmins />
      },

      {
        path: '/dashboard/logs-activity',
        element: <AppLogsActivity />
      },

      {
        path: '/dashboard/logs-api',
        element: <AppLogsAPI />
      },

      {
        path: '/dashboard/list-transaction-mitra',
        element: <AppListTransaction />
      },

      {
        path: '/dashboard/list-area',
        element: <AppListAreas />
      },

      {
        path: '/dashboard/system',
        element: <AppSystem />
      },

      {
        path: '/dashboard/shipping',
        element: <AppShipping />
      },

      {
        path: '/dashboard/payment',
        element: <AppPayment />
      },

      {
        path: '/dashboard/integration/inbound',
        element: <AppIntegrationInbound />
      },

      {
        path: '/dashboard/integration/outbound',
        element: <AppIntegrationOutbound />
      },

      // e-chart rooute
      {
        path: '/charts/echarts',
        element: <AppEchart />,
        auth: authRoles.editor
      }
    ]
  },

  // session pages route
  { path: '/authentication/404', element: <NotFound /> },
  { path: '/authentication/signin', element: <JwtLogin /> },
  { path: '/authentication/signup', element: <JwtRegister /> },
  { path: '/authentication/forgot-password', element: <ForgotPassword /> },
  // { path: '/session/404', element: <NotFound /> },
  // { path: '/session/signin', element: <JwtLogin /> },
  // { path: '/session/signup', element: <JwtRegister /> },
  // { path: '/session/forgot-password', element: <ForgotPassword /> },

  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
