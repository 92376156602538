let url = 'https://sandbox.gudangnibras.com';
// let url = 'https://nibrasgon1.learning-api.online';
let db = '/gon-dev';

if (process.env.REACT_APP_ENV === 'PROD') {
  url = 'https://api.gudangnibras.com';
  db = '/gon-prod';
  // url = 'https://nibrasgon2.learning-api.online';
}

export const base_url = url;
export let namespace = '/api/v1';
export const database = db;
